import { setRemoteDefinitions } from '@nx/angular/mf';

import { environment } from './environments/environment';

const definitions = Object.keys(environment.apps).reduce(
  (meta, key) => ({
    ...meta,
    [key]:
      environment.apps[key].host +
      `/remoteEntry.mjs?sessionId=${new Date().getTime()}.mjs`,
  }),
  {}
);
setRemoteDefinitions(definitions);

// eslint-disable-next-line no-console
import('./bootstrap').catch((err) => console.error(err));
